/* eslint-disable no-multi-spaces */
export const root = process.env.SITE_APP_API_URL;

export const billingApiBase = process.env.BILLING_APP_API_URL;
export const jwacApiBase = process.env.JWAC_APP_API_URL;
export const backOfficeApiBase = process.env.BO_APP_API_URL;

export const apiBase                                   = `${root}/rest`;
export const urlAccount                                = `${apiBase}/account`;
export const urlAccountSelectCustomer                  = `${urlAccount}/selectCustomer`;
export const urlAccountSelectCustomerReset             = `${urlAccountSelectCustomer}/reset`;
export const urlAccountAffiliatedCustomers             = `${urlAccount}/affiliatedCustomers`;
export const urlAccountAffiliatedCustomersSelect       = `${urlAccountAffiliatedCustomers}/[:id]/select`;
export const urlAccountSettingsChangePassword          = `${urlAccount}/changePassword`;
export const urlAccountTemplateKeys                    = `${urlAccount}/templateKeys`;
export const urlAccountSettings                        = `${urlAccount}/settings`;
export const urlAccountSettingsPersonal                = `${urlAccountSettings}/personal`;
export const urlAccountSettingsReport                  = `${urlAccountSettings}/report`;
export const urlAccountSettingsBusinessContact         = `${urlAccountSettings}/businessContact`;
export const urlAccountSettingsIndividualSearches      = `${urlAccountSettings}/individual`;
export const urlAccountSettingsPropertySearches        = `${urlAccountSettings}/propertySearch`;

export const urlCustomers                              = `${apiBase}/customers`;
export const urlCustomersEmailConfirmation             = `${urlCustomers}/emailConfirmation`;
export const urlCurrentCustomer                        = `${urlCustomers}/current`;
export const urlDefaultCustomer                        = `${urlCustomers}/defaultCustomer`;

export const urlUsers                                  = `${apiBase}/users`;
export const urlUsersCurrent                           = `${urlUsers}/current`;
export const urlUsersNew                               = `${urlUsers}/new`;
export const urlUsersResendConfirmationCode            = `${urlUsers}/resendConfirmationCode`;
export const urlUsersUserName                          = `${urlUsers}/[:username]`;
export const urlUsersUserNameActiveState               = `${urlUsersUserName}/activeState`;
export const urlUsersUserNameAdminState                = `${urlUsersUserName}/adminState`;
export const urlAccountIsUsernameUnique                = `${urlAccount}/isUsernameUnique/[:name]`;

export const urlReports                                = `${apiBase}/reports/[:type]`;
export const urlReportsCombine                         = `${apiBase}/reports/combine`;
export const urlReportsResend                          = `${apiBase}/reports/resend`;
export const urlReportsRemoveFromNew                   = `${apiBase}/reports/removeFromNew`;
export const urlReportExportCsv                        = `${apiBase}/reports/[:type]/exportCsv`;

export const urlOrders                                 = `${apiBase}/orders/[:type]`;
export const urlOrdersDeliveryFormData                 = `${apiBase}/orders/orderInitialData`;
export const urlOrdersPropertySearch                   = `${apiBase}/orders/propertySearch/getProperties`;
export const urlOrdersTaxContinuationProperty          = `${apiBase}/orders/propertySearch/tax/continuation/[:id]`;
export const urlOrderConfirmation                      = `${apiBase}/orderConfirmation/[:id]`;
export const urlOrderConfirmationFile                  = `${urlOrderConfirmation}/file`;

export const urlJudgmentLookup                         = `${apiBase}/jdgLookup`;
export const urlJudgmentLookupFile                     = `${urlJudgmentLookup}/file`;

export const urlStates                                 = `${apiBase}/states`;
export const urlMunicipalities                         = `${apiBase}/sct/municipalities`;

export const urlJudgmentChain                          = `${apiBase}/reports/judgment/[:id]/chain`;

export const urlBaseRegistration                       = `${apiBase}/registration`;
export const urlBaseProfile                            = `${apiBase}/profile`;
export const urlBaseLogin                              = `${urlBaseProfile}/login`;
export const urlBaseLogout                             = `${urlBaseProfile}/logout`;
export const urlBasePostVerificationCode               = `${urlBaseProfile}/verificationCode`;
export const urlBaseResetPassword                      = `${urlBaseProfile}/resetPassword`;
export const urlBaseSetFirstPassword                   = `${urlBaseProfile}/setFirstPassword`;
