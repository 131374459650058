import axios from 'axios';
import qs from 'qs';
import { signOut } from '@aws-amplify/auth';
import {
    ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS,
    ACCOUNT_SETTINGS,
} from '@src/utils/constants/routerConstants';
import { logout, singleLogout } from '@src/utils/apiServices/autorizationRequests';

const Axios = axios.create({
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    withCredentials: true,
});

const commonRequestConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
    },
    data: {},
};

export const getRequestConfig = {
    method: 'get',
};

export const postRequestConfig = {
    method: 'post',
    transformRequest: data => (typeof data === 'object' ? JSON.stringify(data) : data?.toString()),
    data: {},
};

export const putRequestConfig = {
    method: 'put',
    data: {},
};

Axios.interceptors.response.use(undefined, async error => {
    if ([401, 403].includes(error.response?.status)) {
        switch (error.response.headers.redirect) {
            case 'COMPLETED_REPORTS':
                document.location.href = ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS;
                break;
            case 'SETTINGS':
                document.location.href = ACCOUNT_SETTINGS;
                break;
            default:
        }
    }

    if (error.response?.status === 401) {
        await signOut({ global: true });
        await Promise.all([
            singleLogout(),
            logout(),
        ]);
    }

    return Promise.reject(error);
});

// Using for any request
export const request = async ({
    data, url, method, ...restParams
}) => {
    const result = await Axios({
        ...commonRequestConfig,
        method,
        url,
        data,
        ...restParams,
    });

    return result.data;
};
