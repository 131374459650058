/**
 * Created by oleg on 12/23/16.
 */
import React from 'react';
import { Col, Row } from 'antd';
import { gutter } from '@src/utils/helpers/orders';

const FinishRegistration = () => (
    <Row justify="center" gutter={gutter}>
        <Col md={16} sm={20} xs={24} className="formBlock">
            I would personally like to thank you for your interest in our services at State Capital.
            We are a family owned and operated business for 100 years.
            At State Capital, we are committed to 100% customer satisfaction.
            <br />
            We are currently processing your new account request.
            You will be receiving an email shortly to confirm your email and set up your password.
            If you have any questions, please do not hesitate to contact our Accounts Department
            at accounts@statecapital.net or call us at (609) 771-4301.
            <br />
            Thank you and we are excited to welcome you into the State Capital family.
            <br />
            <br />
            Christopher John Eler
            <br />
            <a href="mailto:#">chris@statecapital.net</a>
        </Col>
    </Row>
);

export default FinishRegistration;
