import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
} from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { signOut as amplifySignOut, fetchAuthSession } from '@aws-amplify/auth';
import useWindowInFocus from '@src/utils/hooks/useWindowInFocus';
import { logout, singleLogout } from '@src/utils/apiServices/autorizationRequests';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const authenticator = useAuthenticator();
    const isWindowInFocus = useWindowInFocus();
    const userRef = useRef(null);

    const checkSession = async () => {
        if (!isWindowInFocus) {
            return;
        }

        const session = await fetchAuthSession();

        if (
            !session.userSub
            && authenticator.user
            && authenticator.authStatus === 'authenticated'
        ) {
            await amplifySignOut({ global: true });
            await Promise.all([
                singleLogout(),
                logout(),
            ]);
            return;
        }

        if (
            session.userSub
            && !authenticator.user
            && authenticator.authStatus === 'unauthenticated'
        ) {
            window.location.reload();
            return;
        }

        if (
            session.userSub
            && userRef.current
            && session.userSub !== userRef.current
        ) {
            window.location.reload();
            return;
        }

        userRef.current = session.userSub;
    };

    useEffect(() => {
        checkSession();
    }, [isWindowInFocus]);

    const signOut = async () => {
        await amplifySignOut({ global: true });
        await Promise.all([
            singleLogout(),
            logout(),
        ]);
    };

    return (
        <AuthContext.Provider
            value={{
                ...authenticator,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
