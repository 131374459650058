import React, { useLayoutEffect } from 'react';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
    Outlet,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import OrderServiceJudgmentContinuationContent from '@src/containers/orderServiceJudgmentContinuation/OrderServiceJudgmentContinuationContent';
import OrderServicePropertyTaxContinuationContent from '@src/containers/orderServicePropertyTaxContinuation/OrderServicePropertyTaxContinuationContent';
import OrderServicePropertySearchContent from '@src/containers/orderServicePropertySearch/OrderServicePropertySearchContent';

import {
    ORDER_SERVICE_JUDGMENT_LOOK_UP,

    ORDER_SERVICE_JUDGMENT_CONTINUATION_ORDER_FORM,
    ORDER_SERVICE_JUDGMENT_CONTINUATION_SELECT_TABLE,
    ORDER_SERVICE_JUDGMENT_CONTINUATION,

    ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION,
    ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION_ORDER_FORM,

    ORDER_SERVICE_PROPERTY_SEARCH,
    ORDER_SERVICE_PROPERTY_SEARCH_ORDER_FORM_ID,
    ORDER_SERVICE_ABSTRACT_SEARCH,
    ORDER_SERVICE_NAME_SEARCH,

    ACCOUNT_SELECT_CUSTOMER,

    ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS,
    ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS,
    ACCOUNT_VIEW_MY_ORDERS_OPEN_REPORTS,
    ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS_ID,
    ACCOUNT_SETTINGS,
    ROOT,
} from '@src/utils/constants/routerConstants';
import JdgLookupContent from '@src/containers/jdglookup/JdgLookupForm';
import CustomersContent from '@src/containers/customers/CustomersContent';
import NewReportsContent from '@src/containers/newReports/NewReportsContent';
import CompletedReportsContent from '@src/containers/completedReports/CompletedReportsContent';
import OpenOrdersContent from '@src/containers/openOrders/OpenOrdersContent';
import OrderDetailsContent from '@src/containers/orderdetails/components/OrderDetailsContent';
import OrderServiceChanceryContent from '@src/containers/orderServiceChancery/OrderServiceChanceryContent';
import OrderServiceNameSearchContent from '@src/containers/orderServiceNameSearch/OrderServiceNameSearchContent';
import PageNotFoundContent from '@src/containers/pageNotFound/PageNotFoundContent';
import SettingsContent from '@src/containers/settingsContainer/SettingsContent';
import CreditHoldBoundary from '@src/containers/creditHoldBoundary/CreditHoldBoundary';
import OneColumnLayout from '@src/layouts/OneColumnLayout';
import { getCurrentUser } from '@src/store/reducers/userMaintanance/users';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import { getDefaultPage } from '@src/utils/helpers/permissions';
import settingsRoutes from '@src/router/accountSettingsRoutes';
import { isAdmin } from '@src/store/reducers/accountSettings';
import { defaultPageUrls } from '@src/utils/templates/accountSettings';
import { fetchProfile } from '@src/store/actions/authorization';
import { AuthedNavigate } from '@src/containers/auth';

const MainRoutes = () => {
    const dispatch = useDispatch();
    const isAdminRole = useSelector(isAdmin);
    const { defaultPage: defaultPageFromSettings } = useSelector(getCurrentUser);
    const { allowWebOrders, webDelivery } = useSelector(getCurrentCustomer);

    const defaultPage = defaultPageUrls[getDefaultPage(defaultPageFromSettings, allowWebOrders, webDelivery)];

    useLayoutEffect(() => {
        dispatch(fetchProfile());
    }, []);

    const router = createBrowserRouter([
        {
            element: (
                <CreditHoldBoundary>
                    <AuthedNavigate to={defaultPage} />
                    <Outlet />
                </CreditHoldBoundary>
            ),
            children: [
                {
                    path: ROOT,
                    element: <Navigate to={defaultPage} replace />,
                },
                {
                    path: ORDER_SERVICE_JUDGMENT_CONTINUATION,
                    element: (
                        <OneColumnLayout>
                            <Navigate to={ORDER_SERVICE_JUDGMENT_CONTINUATION_SELECT_TABLE} replace />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ORDER_SERVICE_JUDGMENT_CONTINUATION_SELECT_TABLE,
                    element: (
                        <OneColumnLayout>
                            <OrderServiceJudgmentContinuationContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ORDER_SERVICE_JUDGMENT_CONTINUATION_ORDER_FORM,
                    element: (
                        <OneColumnLayout>
                            <OrderServiceJudgmentContinuationContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ORDER_SERVICE_PROPERTY_SEARCH,
                    element: (
                        <OneColumnLayout>
                            <OrderServicePropertySearchContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION,
                    element: (
                        <OneColumnLayout>
                            <OrderServicePropertyTaxContinuationContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION_ORDER_FORM,
                    element: (
                        <OneColumnLayout>
                            <OrderServicePropertySearchContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ORDER_SERVICE_PROPERTY_SEARCH_ORDER_FORM_ID,
                    element: (
                        <OneColumnLayout>
                            <OrderServicePropertySearchContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ORDER_SERVICE_JUDGMENT_LOOK_UP,
                    element: (
                        <OneColumnLayout>
                            <JdgLookupContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ACCOUNT_SELECT_CUSTOMER,
                    element: (
                        <OneColumnLayout>
                            <CustomersContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS,
                    element: (
                        <OneColumnLayout>
                            <NewReportsContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS,
                    element: (
                        <OneColumnLayout>
                            <CompletedReportsContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ACCOUNT_VIEW_MY_ORDERS_OPEN_REPORTS,
                    element: (
                        <OneColumnLayout>
                            <OpenOrdersContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS_ID,
                    element: (
                        <OneColumnLayout>
                            <OrderDetailsContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ORDER_SERVICE_ABSTRACT_SEARCH,
                    element: (
                        <OneColumnLayout>
                            <OrderServiceChanceryContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ORDER_SERVICE_NAME_SEARCH,
                    element: (
                        <OneColumnLayout>
                            <OrderServiceNameSearchContent />
                        </OneColumnLayout>
                    ),
                },
                {
                    path: ACCOUNT_SETTINGS,
                    element: (
                        <OneColumnLayout>
                            <SettingsContent />
                        </OneColumnLayout>
                    ),
                    children: settingsRoutes(isAdminRole),
                },
                {
                    path: '*',
                    element: (
                        <OneColumnLayout>
                            <PageNotFoundContent />
                        </OneColumnLayout>
                    ),
                },
            ],
        },
    ]);

    return (
        <RouterProvider router={router} />
    );
};

export default MainRoutes;
