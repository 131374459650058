import React from 'react';
import { Button } from 'antd';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';
import {
    composeValidators, required,
} from '@src/utils/helpers/validationRules';
import { leadingWhitespacesNormalizer } from '@src/utils/helpers/normalize';
import { useAuth } from './AuthContext';

const focusOnError = createDecorator();

const AuthConfirmSignInForm = () => {
    const { submitForm, isPending, username } = useAuth();

    const onSubmitHandler = values => {
        const confirmationCode = String(values.code).trim().replaceAll(' ', '');

        return submitForm({
            confirmation_code: confirmationCode,
            username: values.email,
        });
    };

    return (
        <Form
            onSubmit={onSubmitHandler}
            decorators={[focusOnError]}
        >
            {
                params => (
                    <form onSubmit={params.handleSubmit}>
                        <Field
                            name="email"
                            component={InputGroupHiddenFieldAdapter}
                            controlId="email"
                            defaultValue={username}
                            autoComplete="email"
                            placeholder="Email"
                        />
                        <Field
                            name="code"
                            component={InputGroupAntInputAdapter}
                            controlId="code"
                            labelText="Code"
                            isRequired
                            validate={composeValidators(
                                required,
                            )}
                            parse={leadingWhitespacesNormalizer}
                            autoComplete="one-time-code"
                            autoFocus
                        />
                        <Button
                            className="right-buffer-xs"
                            htmlType="submit"
                            type="primary"
                            loading={isPending}
                            block
                        >
                            Confirm
                        </Button>
                    </form>
                )
            }
        </Form>
    );
};

export default AuthConfirmSignInForm;
