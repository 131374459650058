import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import AuthSignInForm from './AuthSignInForm';
import AuthForgotPasswordForm from './AuthForgotPasswordForm';
import AuthConfirmResetPasswordForm from './AuthConfirmResetPasswordForm';
import AuthConfirmSignInForm from './AuthConfirmSignInForm';
import { useAuth } from './AuthContext';

const formsMap = {
    signIn: AuthSignInForm,
    forgotPassword: AuthForgotPasswordForm,
    confirmResetPassword: AuthConfirmResetPasswordForm,
    confirmSignIn: AuthConfirmSignInForm,
};

const AuthCustomFormsPortal = () => {
    const { route } = useAuth();
    const [portalElement, setPortalElement] = useState(null);

    const Component = formsMap[route];

    useEffect(() => {
        if (route === 'signIn') {
            const element = document.getElementById('sign-in-form--portal');
            setPortalElement(element);
        } else if (route === 'forgotPassword') {
            const fieldset = document.getElementsByTagName('fieldset').item(0);
            const buttonContainer = fieldset.childNodes.item(fieldset.childNodes.length - 1);
            const target = document.createElement('div');
            target.id = 'forgot-password-form--portal';
            buttonContainer.insertBefore(target, buttonContainer.childNodes.item(0));
            setPortalElement(target);
        } else if (route === 'confirmResetPassword') {
            const fieldset = document.getElementsByTagName('fieldset').item(0);
            const buttonContainer = fieldset.childNodes.item(fieldset.childNodes.length - 1);
            const target = document.createElement('div');
            target.id = 'confirm-reset-password-form--portal';
            buttonContainer.insertBefore(target, buttonContainer.childNodes.item(0));
            setPortalElement(target);
        } else if (route === 'confirmSignIn') {
            const fieldset = document.getElementsByTagName('fieldset').item(0);
            const buttonContainer = fieldset.childNodes.item(fieldset.childNodes.length - 1);
            const target = document.createElement('div');
            target.id = 'confirm-sign-in-form--portal';
            buttonContainer.insertBefore(target, buttonContainer.childNodes.item(0));
            setPortalElement(target);
        } else {
            setPortalElement(null);
        }
    }, [route]);

    if (portalElement && Component) {
        return createPortal(<Component />, portalElement);
    }

    return null;
};

export default AuthCustomFormsPortal;
