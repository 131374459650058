import React from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';

const PatriotSection = ({
    oldNames = [],
    newNames = [],
    areNewNamesSelected,
    gutter,
}) => (
    <div className="serviceBody">
        {
            oldNames.map(({ name }, index) => (
                <Row gutter={gutter} className="section" key={name}>
                    <Col md={24} lg={24}>
                        <Field
                            name={`oldNames.${index}.patriotSearch`}
                            component={InputGroupAntCheckBoxAdapter}
                            controlId={`oldNames.${index}.patriotSearch`}
                            labelText={name}
                            type="checkbox"
                            autoFocus={index === 0}
                        />
                    </Col>
                </Row>
            ))
        }
        {
            newNames
                .filter(({ selectedToContinue }) => selectedToContinue && areNewNamesSelected)
                .map(({ name }, index) => (
                    <Row gutter={gutter} className="section" key={name}>
                        <Col md={24} lg={24}>
                            <Field
                                name={`newNames.${index}.patriotSearch`}
                                component={InputGroupAntCheckBoxAdapter}
                                controlId={`newNames.${index}.patriotSearch`}
                                labelText={name?.toUpperCase() || 'New Name'}
                                disabled={!name}
                                type="checkbox"
                            />
                        </Col>
                    </Row>
                ))
        }
    </div>
);

export default PatriotSection;
