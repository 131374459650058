import moment from 'moment';

const contains = { label: 'Contains', icon: 'contains', value: 'contains' };
const doesnotcontain = { label: 'Does not contain', icon: 'doesnotcontain', value: 'notcontains' };
const startswith = { label: 'Starts with', icon: 'startswith', value: 'startswith' };
const endswith = { label: 'Ends with', icon: 'endswith', value: 'endswith' };
const equal = { label: 'Equal', icon: 'equal', value: '=' };
const notequal = { label: 'Not equal', icon: 'notequal', value: '<>' };

export const matchTypeOptionsDefault = [
    contains,
    doesnotcontain,
    startswith,
    endswith,
    equal,
    notequal,
];

export const integersMatchTypeOptions = [
    equal,
    notequal,
];

export const getFilter = (name, { value: data, matchType }) => {
    const isDatePeriod = data.length === 2 && moment.isMoment(data[0]) && moment.isMoment(data[1]);
    const isString = typeof data === 'string';
    const isArrayOfStrings = Array.isArray(data) && data.every(item => typeof item === 'string');
    const filter = [];

    if (isDatePeriod) {
        const value = [name, matchType, `'${data[0].format()}' and '${data[1].format()}'`];
        value.columnIndex = null;
        filter.push(value);
    }
    if (isString) {
        const value = [name, matchType, data];
        value.columnIndex = null;
        filter.push(value);
    }
    if (isArrayOfStrings) {
        if (matchType === 'in') {
            const value = [name, matchType, `(${data.map(item => `'${item}'`)})`];
            value.columnIndex = null;
            filter.push(value);
        } else {
            data.map((item, index, array) => {
                const value = [name, matchType, item];
                value.columnIndex = null;
                filter.push(value);
                if (index + 1 < array.length) {
                    switch (matchType) {
                        case 'notcontains':
                        case '<>':
                            filter.push('and');
                            break;
                        default:
                            filter.push('or');
                            break;
                    }
                }

                return null;
            });
        }
    }

    return filter;
};

const getObjectValueByKey = (object, key) => (
    key.reduce((accumulator, currentValue) => accumulator[currentValue], object)
);

export const sortDataBySeveralFields = (data, sortingFields) => ([...data].sort((a, b) => {
    let result = 0;

    sortingFields.every(field => {
        if (field) {
            const { selector: fieldName, desc: isSortingDesc } = field;
            const firstElement = isSortingDesc ? b : a;
            const secondElement = isSortingDesc ? a : b;
            const fieldNameChain = fieldName.split('.');
            const firstElementValue = (getObjectValueByKey(firstElement, fieldNameChain) || '').toString().toLowerCase();
            const secondElementValue = (getObjectValueByKey(secondElement, fieldNameChain) || '').toString().toLowerCase();
            if (firstElementValue < secondElementValue) {
                result = -1;
                return false;
            }
            if (firstElementValue > secondElementValue) {
                result = 1;
                return false;
            }
        }

        return true;
    });

    return result;
}));

export const getDataGridFilters = filters => {
    const dataGridFilters = [];
    const filterItems = Object.entries(filters).filter(filterItem => filterItem[1].value.length);
    if (filterItems.length === 1) {
        return getFilter(filterItems[0][0], filterItems[0][1]);
    }

    filterItems.map(
        (filterItem, index, array) => {
            const filterName = filterItem[0];
            const filterData = filterItem[1];
            if (filterData.value.length) {
                const filterValue = getFilter(filterName, filterData);
                dataGridFilters.push(filterValue);
                if (index + 1 < array.length) dataGridFilters.push('and');
            }

            return null;
        },
    );

    return dataGridFilters;
};

export const getSortByFieldComparator = (fieldName, desc = true) => (a, b) => {
    if (a[fieldName] > b[fieldName]) return desc ? 1 : -1;
    if (b[fieldName] > a[fieldName]) return desc ? -1 : 1;

    return 0;
};

export const nonSelectableReportTypes = ['FORECLOSURE', 'MISC_HAMBURGER'];

export const getDisabledKeysFromNewReports = selectedRowsData => selectedRowsData
    .filter(({ typeInfo: { type } }) => nonSelectableReportTypes.includes(type))
    .map(({ id }) => id);

export const getDisabledKeysFromReports = selectedRowsData => selectedRowsData
    .filter(({ type }) => nonSelectableReportTypes.includes(type))
    .map(({ id }) => id);

export const onSelectionChangedHandler = (e, disabledKeysGetter, callBack = () => {}) => {
    let visibleRowsCount = e.component.totalCount();
    const pageSize = e.component.pageSize();
    const disabledKeys = disabledKeysGetter(e.selectedRowsData);
    visibleRowsCount = visibleRowsCount > pageSize ? pageSize : visibleRowsCount;
    const keysFromChainsWithLastUnselectedItem = e.selectedRowsData
        .filter(({ chain }) => chain && !e.selectedRowKeys.includes(chain[0]))
        .map(({ id }) => id);

    if (
        disabledKeys.length > 0
        && e.selectedRowKeys.length === visibleRowsCount
        && e.currentSelectedRowKeys.length !== e.selectedRowKeys.length
    ) {
        e.component.deselectAll();
        return;
    }
    if (disabledKeys.length > 0) {
        e.component.deselectRows(disabledKeys);
        return;
    }
    if (keysFromChainsWithLastUnselectedItem.length > 0) {
        e.component.deselectRows(keysFromChainsWithLastUnselectedItem);
        return;
    }
    callBack(e);
};

export const onCellPreparedHandler = (e, reportType, reportsType) => {
    if (
        e.rowType === 'data'
        && e.column.command === 'select'
        && nonSelectableReportTypes.includes(reportType)
    ) {
        e.cellElement.classList.add('disabled');
    }
    if (reportsType !== 'judgment/judgmentContinuation' || e.rowType !== 'data') {
        return;
    }
    if (
        ['refno', 'donedate'].includes(e.column.dataField)
        || e.column.caption === 'Actions'
        || e.column.command === 'select'
    ) {
        if (e.row.data.chainByOriginLength) {
            e.cellElement.rowSpan = e.row.data.chainByOriginLength;
            e.cellElement.className += e.row.data.chainByOriginLength > 1 ? ' cell-with-row-span' : '';
        } else {
            e.cellElement.style.display = 'none';
        }
    }

    e.cellElement.className += ` ${e.row.data.even ? 'even' : 'odd'}`;
};

export const getSelectedChainsLastReportsIds = reports => reports
    .filter(({ chain, chainByOriginLength }) => (
        chain ? chainByOriginLength : true
    ))
    .map(({ id }) => id);
