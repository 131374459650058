import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const AuthedNavigate = ({ to }) => {
    const { route } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (route === 'authenticated') {
            navigate(to, { replace: true });
        }
    }, [route]);

    return null;
};

export default AuthedNavigate;
